import {Carousel} from 'react-bootstrap' ;



export default function Slider() {


    return(
        <>
          <Carousel fade className='animate__animated animate__fadeIn'>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="Assets/images/slider/robinettere.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="Assets/images/slider/cable.jpg"
              alt="Second slide"
            />

          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="Assets/images/slider/raccord.jpg"
              alt="Third slide"
            />

          </Carousel.Item>
</Carousel>
        </>
    );
}