import React from 'react' ;


export default function Footer() {
return(
        <>
        <footer id="footer">
                        <div className="footer-widgets">
                            <div className="container">

                                <div className="row widget-box">
                                       <div className="col-md-8">
                                            <div className="widget widget-text">
                                                <div className="col-md-2">
                                                    <div id="logo" className="logo">
                                                        <a href=" " title="">
                                                            <img className='logo-footer' src="Assets/images/logo@2x.png" alt="logo Finance Business" />
                                                        </a>
                                                    </div>
                                                </div>
                                                <p className='text-justify'>ALAMIA Société qui a pour activité la fabrication, commercialisation des conducteurs électrique et produit sanitaire.</p>
                                                <p className='text-justify'>
                                                La société est fondée en 1992, dotée d'une grande expérience , avec une équipe de professionnels, l'entreprise produit une large gamme d'articles qui couvrent une grande partie du marché national et international selon les normes en vigueurs.
                                                </p>
                                                <ul class="flat-infomation">
                                                    <li class="phone text-light"><span>Tel : </span>  <a href="tel:+212536701578" title="phone">(+212)5 36 70 15 78   |  </a></li>
                                                    <li className="email text-light">Email: <a href="mailto:alamia-electro@hotmail.com" title="email">   alamia-electro@hotmail.com</a></li>
                                                    <br /><span className=" text-light">27, Rue des Gaïeuls beasite Aïn Sebaâ - Casablanca-Maroc</span>
                                                </ul>
                                            </div>
                                        </div>

                                    <div className="col-md-2 center">
                                            <div className="widget widget-text">
                                                <div id="logo" className="animate__animated animate__pulse">
                                                        <a href="Assets/images/catalogue.pdf" title="alamia-raccord" download>
                                                            <img style={{'boxShadow':'rgb(101 129 143 / 26%) 1px 2px 10px','borderRadius':'8px'}} className="logo-doc"  src="Assets/images/catalogue.jpg" alt="logo Finance Business" />
                                                        </a>
                                                </div>
                                             </div>
                                    </div>
                                    <div className="col-md-2 center">
                                            <div className="widget widget-text">
                                                <div id="logo" className="animate__animated animate__pulse">
                                                        <a href="Assets/images/cable.pdf" title="alamia-cable" download>
                                                            <img style={{'boxShadow':'rgb(101 129 143 / 26%) 1px 2px 10px','borderRadius':'8px'}} className="logo-doc"  src="Assets/images/cable.jpg" alt="logo Finance Business" />
                                                        </a>
                                                </div>
                                             </div>
                                    </div>



                                </div>
                            </div>
                          </div>
            </footer>


        </>
);
}