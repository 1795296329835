import Footer from "./components/commun/Footer";
import Header from "./components/commun/Header";
import { Route,Switch } from "react-router";
import Home from "./components/pages/Home";
import Contact from "./components/pages/Contact";
import About from "./components/pages/About";
import Catalogue from "./components/pages/Catalogue";
import Electricite from "./components/cable/Electricite";
import Electricites from "./components/pages/Electricites";
import Robinettere from "./components/robinettere/Robinettere";
import Raccord from "./components/raccord/Raccord";
import RdDetails from "./components/raccord/RdDetails";
import RbDetails from "./components/robinettere/RbDetails";


import { useEffect, useState } from "react";
import NotFound from "./components/pages/NotFound";
import ElDetails from "./components/cable/ElDetails";





function App() {


  const[loading , setLoading] = useState(false) ;
  const [error, setError] = useState(null);

  const a = navigator.onLine ;

  console.log(!navigator.onLine) ;

  useEffect( () => {
    
    setLoading(true) ;

    
    
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000);
    return () => clearTimeout(timer);
  }, []);



  return (
    <>
    <div className="boxed">
      {
        loading  ?
        <div className="preloader">
        <div className="clear-loading loading-effect-2">
            <span></span>
        </div>
       </div>
       :
       <>
       <Header />
       <Switch>
           <Route exact path="/"><Home /></Route>
           <Route path="/contact"><Contact /></Route>
           <Route path="/about"><About /></Route>
           <Route path="/catalogue"><Catalogue /></Route>
           <Route path="/robinettere"><Robinettere /></Route>
           <Route path="/raccord"><Raccord /></Route>
           <Route path="/electricite"><Electricite /></Route>
           <Route path='/RacDetails/:RacId'><RdDetails /></Route>
           <Route path='/RobDetails/:RobId'><RbDetails /></Route>
           <Route path='/ElDetails/:EleId'><ElDetails /></Route>
           <Route path="*"><NotFound /></Route>
       </Switch>
       <Footer />
     
      <div className="button-go-top">
        <a href="go-top" title="up" className="go-top">
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
       </>
      }
    </div>
    </>
  );
}

export default App;
