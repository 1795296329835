import React, { useEffect } from 'react' ;
import {Link} from 'react-router-dom';
import {useState} from 'react' ;
import {GiHamburgerMenu} from 'react-icons/gi';
import {GrClose} from 'react-icons/gr';

import {FaFacebook} from 'react-icons/fa';
import {FaInstagram} from 'react-icons/fa';
import {FaYoutube} from 'react-icons/fa';

export default function Header(){


const [click, setClick] = useState() ;


// useEffect( () =>{
// setClick(false);
// alert.log(setClick())
// } , [click])


const handelClick = () =>{

	setClick(!click);

}




    return(
        <>
		<div className="top">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="flat-infomation" style={{'marginBottom':'auto'}}>
                            <li className="phone">Tel : <a href="tel:+212536701578" title="phone">(+212)5 36 70 15 78</a></li>
                            <li className="email">Email: <a href="mailto:alamia-electro@hotmail.com" title="email">alamia-electro@hotmail.com</a></li>
                        </ul>
						<div class="flat-questions animate__animated animate__pulse">
                            <Link to="/contact" title="" class="questions ">  Avez-vous des questions?</Link>
                        </div>
						<div class="flat-questions">
					    	<Link to="/" title="social-media" class="social-media animate__animated animate__pulse"><FaFacebook /></Link>
							<Link to="/" title="social-media" class="social-media"><FaInstagram /></Link>
							<Link to="/" title="social-media" class="social-media"><FaYoutube /></Link>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        </div>
        <header id="header" className="header transparent style2">
			<div className="container">
				<div className="">
					<div className="header-wrap" style={{'display':'flex','alignItems':'center'}}>
						<div className="col-md-4 ">
							<div id="logo" className="logo">
								<Link to="/" title="" style={{'display':'flex' , 'alignItems':'center' , 'justifyContent':'flex-start'}}>
									<img className='animate__animated animate__fadeInLeft' src="Assets/images/logo@2x.png" alt="logo Finance Business" />
									<h5 className='animate__animated animate__fadeInRight' style={{'color':'rgb(160 1 1 / 82%)' ,'margin-left':'15px' ,'font-weight':'600' ,'marginTop':'11px'}}>Alamia Electro</h5>
								</Link>
							</div>
						</div>
						<div className="col-md-7">
							<div className="nav-wrap">
                                <div className="btn-menut" onClick={handelClick}>
                                    <>{click ? <GrClose className="btn-menu" /> : <GiHamburgerMenu className="btn-menu" />}</>
                                </div>
								<nav id={click ? 'mainnav-mobi' :'mainnav'} className="mainnav animate__animated animate__fadeIn">
									<ul className="menu">
										<li>
											<Link to="/about">Société</Link>
											{/* <a href="/about">Société</a> */}
										</li>
										<li>
											<Link  to="/raccord" title="">Raccord</Link>
											{/* <a href="/raccord">Raccords</a> */}
										</li>
										<li>
											<Link  to="/robinettere">Robinetterie</Link>
											{/* <a href="/robinettere">Robinetterie</a> */}
										</li>
										<li>
											<Link  to="/electricite">Câble</Link>
											{/* <a href="/catalogue">Catalogues</a> */}
										</li>
										<li>
											<Link  to="/catalogue">Catalogues</Link>
											{/* <a href="/catalogue">Catalogues</a> */}
										</li>
										<li>
                                             <Link  to="/contact">Contact</Link>
											 {/* <a href="/contact">Contact</a> */}
										</li>
									</ul>
								</nav>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
        </>
    );

}
