import React, {useState} from 'react' ;
import {useParams} from 'react-router-dom';
import dataEl from '../../data/dataEl.json' ;
import {Link} from 'react-router-dom'

import {AiOutlineMenu} from 'react-icons/ai';
import {AiOutlineMenuUnfold} from 'react-icons/ai';
import {ImArrowRight2 , ImArrowDown2} from 'react-icons/im' ;


import {Accordion} from 'react-bootstrap';


export default function ElDetails(){

        const {EleId} = useParams() ;
        const data = dataEl.find(item => item.id == EleId ) ;


	const getUnique = (items , value) =>{
		return [...new Set(items.map(item => item[value]))]
	}

	let dt = getUnique(dataEl , "categories") ;
	console.log(dt);


        const[click , setClick] = useState() ;
        const[click1 , setClick1] = useState() ;


        const clickHandler = () =>{
             setClick(!click)
        }

        const clickHandler1 = () =>{
                setClick1(!click1)
           }
        
     


return(
        <>
        
        <div className="pre-content" style={{'padding':"1% 0"}}></div>
            <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>câbles électriques / {data?.title}</span>
            </div>
          <section className="flat-row pd-aboutv3 ">
            <div className='container' style={{'display':'flex','alignItems':'baseline','justifyContent':'flexStart'}} >
               <span onClick={clickHandler} ><span style={{'color':'#808080' ,'marginRight' : '10px','cursor':'pointer'}} >{click ? <AiOutlineMenu />  : <AiOutlineMenuUnfold />}</span> </span><h5 style={{'color':'#9C2102'}}>câbles électriques - {data?.title}</h5>
            </div>
				<div className="container">
					<div className="row flat-tabs" data-effect="fadeIn">
                                               <div className="col-md-3" style={{display: click ? 'none' : 'block' }}>
							<div className="sidebar left">
								<aside className="widget widget_nav_menu">
									<div className="menu-services-container">
										<ul className="menu menu-tab animate__animated animate__fadeInLeft">
                                        <Accordion defaultActiveKey={0} style={{'backgroundColor':'transparent','border':'none','padding':'0'}}>
                                            {
                                                // dt.map((item , indexs) => 
												<>
												<Accordion.Item eventKey={0}>
												<Accordion.Header onClick={clickHandler1}>Câbles électriques<span >{ click1 ? <ImArrowRight2 className='animate__animated animate__jackInTheBox' /> : <ImArrowDown2 className='animate__animated animate__jackInTheBox' />}</span></Accordion.Header>
												<Accordion.Body>
												{dataEl.map((items , index)   => 
                                                 items.categories != "" ? <li    className='animate__animated animate__zoomIn' key={index}> <Link className='item-Raccord text-dark' to={`${items.id}`}>{items.categories != "" ? items.AME : '-'}  <img style={{'height':'18px','marginLeft':'30px','marginRight':'20px'}} src={items.image} alr=" " /> </Link></li> : ''
												)}
												</Accordion.Body>
												</Accordion.Item>
                                                </>
												// )
                                            }
											</Accordion>
                                        </ul>
									</div>
								</aside>
							</div>
						</div>
						<div class="col-md-9 content-tab">
							<div class="content-inner">
								<div class="wrap-main-post about-v3 animate__animated animate__fadeInRight">
                                    <h4 className='produit-title'>{data?.AME}</h4>
                                    <h1 className='produit-details'><span className='text-dark'>ISOLATION  : </span>{data?.ISOLATION}</h1>
                                    <h1 className='produit-details'><span className='text-dark'>COULEUR : </span>{data?.couleur ? data?.couleur : '-'}</h1>
                                    <h1 className='produit-details'><span className='text-dark'>CODE : </span>{data?.code ? data?.code : '-'}</h1>
                                    <h1 className='produit-details'><span className='text-dark'>TENSION DE SERVICE : </span>{data?.tensionService ? data?.tensionService : '-' }</h1>
                                    <h1 className='produit-details'><span className='text-dark'>TENSION D'ESSAI : </span>{data?.tensionEssai ? data?.tensionEssai : '-'}</h1>
                                    <h1 className='produit-details'><span className='text-dark'>SECTION (mm) : </span>{data?.SECTION ? data?.SECTION : '-'}</h1>
                                    <div  style={{'position':'relative','margin':'auto','overflow':'hidden'}}>
                                    <img className='img-produit' src={data?.image} alt="img" />
                                    </div>  
								</div>
							</div>
						</div>
                    </div>
				</div>
			</section>
        <div style={{'padding':'10%'}}>
        </div>
        </>


) ;


}