import React from 'react' ; 
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2' ;



export default function Contact() {


	// function handleClick() {
	// 	setErrors(validate(values));
	// 	setIsSubmitting(true);
	// };
	function sendEmail(e) {
        e.preventDefault();
            emailjs.sendForm('service_89ombzc', 'tomobilat-template', e.target, 'user_0gVHKRYGa7kwcgTlEBo3a')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
          Swal.fire({
			title: 'ALAMIA',
			text: "Votre Email bien Envoyer",
			icon: 'success',
			showClass: {
				popup: 'animate__animated animate__fadeInDown'
			  },
			  hideClass: {
				popup: 'animate__animated animate__fadeOutUp'
			  }
		  });
         e.target.reset();
      }

    return(
    <>
            <div className="pre-content" style={{'padding':"1% 0"}}></div>
            <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>Contact</span>
            </div>
            <section className="flat-row">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="title-section center s1 pd-title-section">
								<h2>Contactez-nous</h2>
								<p className="sub-title-section">Utilisez le formulaire ci-dessous pour entrer en contact avec l'équipe commerciale .</p>
							</div>
							<div className="dividers dividers-imagebox v1"></div>
						</div>
					</div>
				</div>
			</section>
            <section className="flat-row pd-contact-v2">
				<div className="container">
					<div className="row">
						<div className="col-md-4">
							<div className="contact-info">
								<div className="info info-address">
									<div className="title">Adresse </div>
									<p>Zone Industrielle BP/529 <br />Oujda-Maroc</p>
									<div className="title">Showroom </div>
									<p>27, Rue des Gaïeuls beasite Aïn Sebaâ<br />Casablanca-Maroc</p>
								</div>


								<div className="info info-address">
									<div className="title">Téléphone</div>
									<p>(+212)5 36 70 15 78</p>
								</div>

								<div className="info info-address">
									<div className="title">Adresse e-mail</div>
									<p>alamiaelectro.2020@gmail.com</p>
								</div>
							</div>
						</div>

						<div className="col-md-8">
							<div className="flat-form-info">
								<form    className="form-info" onSubmit={sendEmail}>
									<div className="one-half v3">
										<p className="input-info"><input type="text" name="name" id="name" placeholder="Nom"  /></p>
										<p className="input-info"><input type="email" name="email" id="email" placeholder="Email" required/></p>
										<p className="input-info"><input type="text" name="subject" id="subject" placeholder="Sujet" required/></p>
									</div>
									<div className="one-half v4">
										<p className="input-info"><textarea id="message-contact" name="message" placeholder="Message" required></textarea></p>
									</div>
									<div className="one-half v3">
									<p className="input-info"><button type="submit" class="submit">Envoyer</button></p>
									</div>
								</form>
							</div>
						</div>

					</div>
				</div>
			</section>
    </>
    );
};

