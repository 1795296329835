export default function NotFound(){


    return(
        <>

            <div className="pre-content" style={{'padding':"1% 0"}}></div>
            
            <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>Page non trouvée </span>
            </div>

            <section className="page-not-found" style={{'padding' : '5%'}}>
           
            
					<img src="/Assets/error-404.png" alt=" " />
			</section>
        </>
    );
}