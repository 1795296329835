import React from 'react' ;
import {Link} from 'react-router-dom';
import Slider from '../features/Slider';




export default function Home() {

    return(
        <>
         {/* <div id="carouselExampleIndicators" className="carousel slide animate__animated animate__fadeIn" data-ride="carousel">
				
				<div className="carousel-inner">
				<div className="carousel-item active">
					<img className="d-block w-100" src="Assets/images/slider/robinettere.jpg" alt="First slide" />
				</div>
				<div className="carousel-item" >
					<img className="d-block w-100" src="Assets/images/slider/cable.jpg" alt="Second slide" />
				</div>
				<div className="carousel-item" >
					<img className="d-block w-100" src="Assets/images/slider/raccord.jpg" alt="Third slide" />
				</div>
				</div>
				<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="sr-only">Previous</span>
				</a>
				<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="sr-only">Next</span>
				</a>
		  </div> */}

		<Slider />
		<section className='title-section animate__animated animate__fadeIn'>
		       <h4>40 ans de fabrication de robinetterie sanitaire et de raccords en laiton</h4>
		</section>


		<section className="flat-row pd-imagebox">
			<div className="container">
				<div className="row">
                <div className="col-md-12">
                  <div className="title-section center">
                    <h2>Nos Produits</h2>
                  </div>
                  </div>
					<div className="col-md-4 animate__animated animate__pulse">
						<div className="imagebox-item">
							<div className="imagebox style1">
								<div className="imagebox-image">
									<img src="Assets/images/imagebox/001.jpg" alt="" />
								</div>
								<div className="imagebox-content">
									<div className="imagebox-desc">
                                    Démontables ou encastrables, elles s'installent même par les bricoleurs débutants, indiqué pour les montages sans soudure, le raccord automatique raccordement appareil sanitaire pas cher convient à l'évacuation des eaux usées .
									</div>
									<div className="imagebox-button">
										<Link to="/robinettere" title="">Voir plus</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-4 animate__animated animate__pulse">
						<div className="imagebox-item">
							<div className="imagebox style1">
								<div className="imagebox-image">
									<img src="Assets/images/imagebox/002.jpg" alt=" " />
								</div>
								<div className="imagebox-content">
									<div className="imagebox-desc">
                                    Cable et fil électriques pour installation en courant fort: cable électrique pour alimentation de maison, de luminaires extérieurs, de machines électriques.
									</div>
									<div className="imagebox-button">
                                        <Link to="/electricite">Voir plus</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-md-4 animate__animated animate__pulse">
						<div className="imagebox-item">
							<div className="imagebox style1">
								<div className="imagebox-image">
									<img  src="Assets/images/imagebox/003.jpg" alt="" />
								</div>
								<div className="imagebox-content">
									<div className="imagebox-desc">
                                    A visser ou mécaniques, les pièces de raccordement pour appareil sanitaire sont simples ou complexes, selon votre installation de plomberie.
									</div>
									<div className="imagebox-button">
                                    <Link to="/raccord">Voir plus</Link>
									</div>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</section>

        <div class="pre-content"></div>

        </>
    );
    
};

