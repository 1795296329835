import React, { useEffect, useState } from 'react' ;
import {Link} from 'react-router-dom';
import dataEl from '../../data/dataEl.json' ;

import {AiOutlineMenuUnfold} from 'react-icons/ai' ;
import {AiOutlineMenu} from 'react-icons/ai';
import {ImArrowRight2} from 'react-icons/im' ;
import {ImArrowDown2} from 'react-icons/im' ;

import { Accordion } from 'react-bootstrap';




export default function Electricite() {


	let datas =  dataEl.filter(i => i.category == "CATEGORIE 1")
	const getUnique = (items , value) =>{
		return [...new Set(items.map(item => item[value]))]
	}

	let dt = getUnique(dataEl , "categories") ;
	console.log(dt);

   const[click , setClick] = useState() ;
   const[click1 , setClick1] = useState() ;

   const clickHandler = () =>{
	setClick(!click)
   }

   const clickHandler1 = () =>{
	setClick1(!click1)
   }

   const[loading , setLoading] = useState(false);

   useEffect(() => {
	   setLoading(true)
	   const timer = setTimeout(() => {
		setLoading(false)
	  }, 1000);
	  return () => clearTimeout(timer);
   }, [])



    return(
    <>
           <div className="pre-content"></div>
            <div className='container breadcums' >
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>câbles électriques</span>
            </div>
			<section className="flat-row pd-aboutv3 " >
			<div className='container' style={{'display':'flex','alignItems':'baseline','justifyContent':'flexStart'}} >
               <span onClick={clickHandler} ><span style={{'color':'#808080' ,'marginRight' : '10px','cursor':'pointer'}} >{click ? <AiOutlineMenu />  : <AiOutlineMenuUnfold />}</span> </span ><h5 style={{'color':'#9C2102'}}>Câbles électriques</h5>
            </div>

				<div className="container">
					<div className="row flat-tabs" data-effect="fadeIn" >
						<div className="col-md-3" style={{display: click ? 'none' : 'block' }}>
							<div className="sidebar left">
								<aside className="widget widget_nav_menu">
									<div className="menu-services-container">
										<ul className="menu menu-tab animate__animated animate__fadeInLeft">
                                            <Accordion defaultActiveKey="0" style={{'backgroundColor':'transparent','border':'none','padding':'0'}}>
                                            {
                                                // dt.map((item , indexs) => 
												<>
												<Accordion.Item eventKey={0}>
												<Accordion.Header onClick={clickHandler1}>câbles électriques<span >{ click1 ? <ImArrowDown2 className='animate__animated animate__jackInTheBox' /> : <ImArrowRight2 className='animate__animated animate__jackInTheBox' />}</span></Accordion.Header>
												<Accordion.Body>
												{dataEl.map((items , index)   => 
                                                 items.categories != "" ? <li   className='animate__animated animate__zoomIn' key={index}> <Link className='item-Raccord text-dark' to={`ElDetails/${items.id}`}>{items.categories != "" ? items.title : '-'}  <img style={{'height':'18px','marginLeft':'30px','marginRight':'20px'}} src={items.image} alr=" " /> </Link></li> : ''
												)}
												</Accordion.Body>
												</Accordion.Item>
                                                </>
												// )
                                            }
											</Accordion>

										</ul>
									</div>
								</aside>
							</div>
						</div>
						<div class="col-md-9 content-tab">
							<div class="content-inner">
								<div class="wrap-main-post about-v3">
											<div className='row' style={{'justifyContent':'center'}}>
												{
													loading
													?
													<img className='img-loading-item' src="/Assets/load1.gif" alt=" " />
													:
													dataEl.slice(0,21).map((item , index) =>
													<>
													<div  className='Item-RC md-col-2 bg-white animate__animated animate__zoomIn'>
														<Link to={`ElDetails/${item.id}`}><img src={item.image} alt="img" /></Link>
														<Link className='Item-RC-hover'  to={`ElDetails/${item.id}`}>
													   	<h6>{item.title}</h6>
														</Link>
													</div>
													</>
													)
												}
											</div>
								</div>
							</div>
						</div>
                    </div>
				</div>
			</section>
    </>
    );
};

