import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';


ReactDOM.render(

  <HashRouter>
    {/* <BrowserRouter> */}
    <App />
     {/* </BrowserRouter> */}
  </HashRouter>

  ,
  document.getElementById('root')
);

