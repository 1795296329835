import React from 'react' ;




export default function About() {
    return(
    <>
           <div className="pre-content"></div>
           <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>Société</span>
            </div>
            <section className="flat-row">
				<div className="container">
					<div className="row">
						<div className="col-md-12">
							<div className="title-section center s1 pd-title-section animate__animated animate__fadeIn">
								<h2>ALAMIA ELECTRO</h2>
								<p className="sub-title-section">
                                Société qui a pour activité la fabrication, commercialisation des conducteurs  et produits électriques.

                                La société est créée en 1992, dotée d'une grande expérience , avec une équipe de professionnels, l'entreprise produit une large gamme d'articles qui couvrent une grande partie du marché national et international selon les normes en vigueur.

                                Membre actif au sein de la FENELEC, ALAMIA ELECTRO fait partie des entreprises spécialisées dans le secteur électrique.

                                ALAMIA ELECTRO  fondée  par le groupe HOUAT, elle fait partie d'un groupe de sociétés spécialisées dans la fabrication et la commercialisation des produits électriques.
                                </p>
							</div>
							<div className="dividers dividers-imagebox v1"></div>

                            <div className='row'>
                                <div className='col-md-7 animate__animated animate__fadeInLeftBig'>
                                    <div className="box-content">
										<div className="title">Généralité</div>
										<p>Nous produisons des raccords, des vannes, des robinets et des collecteurs en laiton. ALAMIA SANITAIRE est une entreprise dynamique en constante évolution et qui a fait de la qualité des produits et des processus commerciaux sa priorité. Un personnel technique et commercial hautement qualifié, une gestion  renouvelée constamment présente dans l'entreprise, la tradition et L’innovation se conjuguent pour être les protagonistes du marché.</p>
										<div className="dividers dividers-bc-v4"></div>
									</div>
                                </div>
                                <div className='col-md-5 mh-100 animate__animated animate__fadeInRightBig'>
                                    <img style={{'borderRadius': '12px','boxShadow': '-1px 0px 3px 1px #1f0c0724'}} className='mh-100 w-100 p-3' src="Assets/images/imagebox/002.jpg" alt="img" />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-5 animate__animated animate__fadeInLeftBig'>
                                    <img style={{'borderRadius': '8px','boxShadow': '-1px 0px 3px 1px #1f0c0724'}} className='mh-50 w-100 p-3 d-inline-block' src="https://mennconcept.com/wp-content/uploads/2019/12/4de87ee584873d8c1707a467879ec443.jpg" alt="img" />
                                </div>
                                <div className='col-md-7 animate__animated animate__fadeInRightBig'>
                                    <div className="box-content">
										<div className="title">Fabriqué au MAROC</div>
										<p>Notre produit est fabriqué au MAROC, Les matières premières d'origine certifiées, traitées exclusivement au sein de notre département de production, représentent pour nos clients la garantie d'un produit de haute qualité. Le contrôle constant et l'attention aux détails font également partie de notre Politique.</p>
										<div className="dividers dividers-bc-v4"></div>
									</div>
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div class="box-content">
										<div className="title">Vaste choix</div>
										<p>Nous offrons à nos clients un large choix de produits : raccords pour différents systèmes et types de tuyaux, robinets à tournant sphérique et robinets à potence ainsi que les collecteurs de distribution. De plus, chaque ligne de produits propose une large gamme de tailles et de formes. Grâce à cet avantage, nos clients peuvent toujours trouver dans notre catalogue ce dont ils ont besoin.</p>
										<div className="dividers dividers-bc-v4"></div>
									</div>
                                </div>
                                <div className='col-md-5 mh-100'>
                                    <img style={{'borderRadius':'8px','boxShadow': '-1px 0px 3px 1px #1f0c0724'}} className='mh-100 w-100 p-3 d-inline-block' src="https://comptoir.bricozor.com/wp-content/uploads/2014/07/racoord-plomberie.jpg" alt="img" />
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-5'>
                                    <img style={{'borderRadius':'8px','boxShadow': '-1px 0px 3px 1px #1f0c0724'}} className='mh-50 w-100 p-3 d-inline-block' src="https://www.plomberie-sanitaire.net/wp-content/uploads/2018/04/raccords_laiton-768x0-c-default.jpg" alt="img" />
                                </div>
                                <div className='col-md-7'>
                                    <div className="box-content">
										<div className="title">Haute qualité</div>
										<p>Matières premières sélectionnées, certificats délivrés par les plus grandes institutions internationales, contrôles entrants et sortants, département qualité bien structuré : c'est ce que la qualité signifie pour nous. Elle concerne non seulement le produit mais également l'ensemble de notre personnel qui travaille quotidiennement de manière professionnelle afin de faire de notre entreprise une référence pour chaque client</p>
										<div className="dividers dividers-bc-v4"></div>
									</div>
                                </div>
                            </div>
                            <br />
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className="box-content">
										<div className="title">Respecter l'environnement </div>
										<p>L’industrie synonyme de pollution et d'inattention à l'environnement? Plus maintenant.
                                            Ces dernières années, de nombreuses entreprises, manufacturières ou non, ont porté leur attention sur la protection de l'environnement, et les aménagements généraux en font partie. Comment?
                                            Faire attention à l'énergie et aux déchets de papier, utilisation des matières premières certifiées pour la production, de production soigné. Pourquoi?  Être «vert» n'est pas une tendance, mais un besoin. Pour être mis à jour avec les normes internationales les plus avancées, pour être éthiquement correct, pour réussir à tout point de vue.
                                            </p>
										<div className="dividers dividers-bc-v4"></div>
									</div>
                                </div>
                                <div className='col-md-5 mh-100'>
                                    <img style={{'borderRadius':'8px','boxShadow': '-1px 0px 3px 1px #1f0c0724'}} className='mh-100 w-100 p-3 d-inline-block' src="https://www.pharmaleo.fr/media/news/img/normal/terre1.jpg" alt="img" />
                                </div>
                            </div>
                            <br />

                            <br />



						</div>
                        <div className="dividers dividers-imagebox v1"></div>
					</div>
				</div>
			</section>

    </>
    );
};

