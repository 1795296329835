import React, {useState} from 'react' ;
import {useParams} from 'react-router-dom';
import dataRb from '../../data/dataRb.json' ;
import {Link} from 'react-router-dom'

import {AiOutlineMenu} from 'react-icons/ai';
import {AiOutlineMenuUnfold} from 'react-icons/ai';
import {ImArrowRight2} from 'react-icons/im' ;
import {ImArrowDown2} from 'react-icons/im' ;

import {Accordion} from 'react-bootstrap';

import {BsArrowBarLeft} from 'react-icons/bs';



export default function RbDetails(){

        const {RobId} = useParams() ;
        const data = dataRb.find(item => item.id == RobId ) ;

        
	const getUnique = (items , value) =>{
		return [...new Set(items.map(item => item[value]))]
	}

	let dt = getUnique(dataRb , "categories") ;
	console.log(dt);


        const[click , setClick] = useState() ;
        const[click1 , setClick1] = useState() ;


        const clickHandler = () =>{
             setClick(!click)
        }

        const clickHandler1 = () =>{
                setClick1(!click1)
           }
        
     


return(
        <>
        
        <div className="pre-content" style={{'padding':"1% 0"}}></div>
            <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>Robinetterie / {data?.title}</span>
            </div>
          <section className="flat-row pd-aboutv3 ">
            <div className='container' style={{'display':'flex','alignItems':'baseline','justifyContent':'flexStart'}} >
               <span onClick={clickHandler} ><span style={{'color':'#808080' ,'marginRight' : '10px','cursor':'pointer'}} >{click ? <AiOutlineMenu />  : <AiOutlineMenuUnfold />}</span> </span><h5 style={{'color':'#9C2102'}}>Robinetterie - {data?.title}</h5>
            </div>
				<div className="container">
					<div className="row flat-tabs" data-effect="fadeIn">
                                               <div className="col-md-3" style={{display: click ? 'none' : 'block' }}>
							<div className="sidebar left">
								<aside className="widget widget_nav_menu">
									<div className="menu-services-container">
										<ul className="menu menu-tab animate__animated animate__fadeInLeft">
                                                                                <Accordion defaultActiveKey={0} style={{'backgroundColor':'transparent','border':'none','padding':'0'}}>
                                            {
                                                // dt.map((item , indexs) => 
												<>
												<Accordion.Item eventKey={0}>
												<Accordion.Header onClick={clickHandler1}>Robinetterie<span >{ click1 ? <ImArrowDown2 className='animate__animated animate__jackInTheBox' /> : <ImArrowRight2 className='animate__animated animate__jackInTheBox' />}</span></Accordion.Header>
												<Accordion.Body>
												{dataRb.map((items , index)   => 
                                                 items.categories != "" ? <li className='animate__animated animate__zoomIn' key={index}> <Link className='item-Raccord text-dark' to={`${items.id}`}>{items.categories != "" ? items.title : '-'}  <img style={{'height':'18px','marginLeft':'30px','marginRight':'20px'}} src={items.image[0]} alr=" " /> </Link></li> : ''
												)}
												</Accordion.Body>
												</Accordion.Item>
                                                </>
												// )
                                            }
											</Accordion>
                                                                                </ul>
									</div>
								</aside>
							</div>
						</div>
						<div class="col-md-9 content-tab">
							<div class="content-inner">
								<div class="wrap-main-post about-v3 animate__animated animate__fadeInRight">

                                                                <h4 className='produit-title'>{data?.title}</h4>
                                                                <h1 className='produit-details'>{data?.content ? data?.content : '-'}</h1>
                                                                <h1 className='produit-details'><span className='text-dark'>SERIE :</span> {data?.serie ? data?.serie : '-'}</h1>
                                                                <div>
                                                                <div style={{'display':'flex','flexWrap':'wrap' , 'flexDirection':'column'}}>
                                                                {
                                                                        data?.image.map((item,key) =>

                                                                                <div  style={{'position':'relative','margin':'auto','overflow':'hidden' , 'width':'100%'}}>
                                                                                        
                                                                                <img className='img-produit' src={item} alt="img" />
                                                                                </div>
                                                                        )
                                                                }
                                                                {
                                                                        data?.table.map((item,key) =>
                                                                        <img style={{'width':'85%','height':'auto'}} className='img-details ' src={item} alt="" />
                                                                        )
                                                                }
                                                                 </div>
                                                               </div>
								</div>
							</div>
                                                       <div style={{'marginTop':'8%'}}><Link to="/robinettere" style={{'textDecoration':'none'}}><h1 style={{'display':'flex','align-items':'center'}} className='produit-details'><BsArrowBarLeft /> Listes des robinets</h1></Link></div> 
						</div>
                    </div>
				</div>
			</section>
        <div style={{'padding':'10%'}}>
        </div>
        </>


) ;


}