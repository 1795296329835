import React from 'react' ; 
import { Accordion } from 'react-bootstrap';

export default function Catalogue() {
    return(
    <>
    

            <div className="pre-content" style={{'padding':"1% 0"}}></div>
            <div className='container breadcums'>
               <span><i className='fa fa-home'> </i> <a className='text-dark' href="/">Accueil</a>   /</span> <span>Catalogues</span>
            </div>

            <section className="flat-row pd-aboutv3 ">
				<div className="container">
					<div className="row flat-tabs" data-effect="fadeIn">
						<div className="col-md-3">
							<div className="sidebar left">
								<aside className="widget widget-brochure services">
									<div className="brochure-box-title">
										<h5 className="brochure-title">Nos catalogues</h5>
										<p>Consultez nos catalogues
											pour un guide facile à lire sur tous les
											Produits offerts.
										</p>
									</div>
									{/* <p className="btn-download animate__animated animate__pulse">
										<a href="Assets/images/catalogue.pdf"  title="" className="pdf" download>Télécharger .PDF</a>
									</p> */}
								</aside>
							</div>
						</div>
                        <div className="col-md-9 content-tab">
							<div className="content-inner">
                             <section className='flat-row'>
                                <div className="container">
                                    <div className="row center">
                                    <div className="col-md-5 ">
                                    <a href="Assets/images/catalogue.pdf"  title="" className="pdf" download>
                                    <img className="h-75 catalogue-img" style={{'boxShadow':'rgb(101 129 143 / 26%) 1px 2px 10px','borderRadius':'8px'}}  src="Assets/images/catalogue.jpg" alt="img" />
                                    </a>
                                    </div>
                                    <div className="col-md-5 ">
                                    <a href="Assets/images/cables.pdf"  title="" className="pdf" download>
                                    <img className="h-75 catalogue-img" style={{'boxShadow':'rgb(101 129 143 / 26%) 1px 2px 10px','borderRadius':'8px'}} src="Assets/images/cable.jpg" alt="img" />
                                    </a>
                                    </div>
                                    </div>
                                </div>
                               </section>
                            </div>
                        </div>
                    </div>
				</div>
			</section>
    </>
    );
};

